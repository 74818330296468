<template>
  <se-advisor-top-advisors-list>
    <template #header>
      <div class="w-full md:w-10/12 xl:w-[53%] pb-16 md:pb-32">
        <h2 class="text-h-xl md:text-h-2xl font-semibold pb-8 md:pb-16">
          {{ topAdvisors.title }}
        </h2>
        <p v-if="totalSessions" class="text-b-s md:text-b-xl font-medium text-neutral-80">
          {{ topAdvisors.descriptionOne }} {{ totalSessions }} {{ topAdvisors.descriptionTwo }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center pt-16 md:pt-32 w-full">
        <Link href="/advisors/top?page=2" class="text-black no-underline w-full md:w-auto">
          <se-button size="large" type="tertiary" class="bg-white border border-neutral-10  hidden md:inline-flex md:visible">
            {{ topAdvisors.ctaText }}<se-icon name="arrow-right" :size="20" class="ml-6" />
          </se-button>
          <se-button size="medium" type="tertiary" class="bg-white border border-neutral-10 inline-flex md:hidden ml-0 w-full">
            {{ topAdvisors.ctaText }}<se-icon name="arrow-right" :size="16" class="ml-6" />
          </se-button>
        </Link>
      </div>
    </template>
  </se-advisor-top-advisors-list>
</template>
<script setup lang="ts">
import SeAdvisorTopAdvisorsList from '~/modules/advisor/components/top-advisors-list.vue';
import { theme } from '../../../../../../theme';
import SeButton from '../../../../../shared/ui-kit/button/Button.vue';
import Link from '../../../../../components/Link.vue';
import SeIcon from '../../../../../shared/ui-kit/icon/Icon.vue';

const { totalSessions } = defineProps({
  totalSessions: {
    type: String,
    required: false,
    default: '0',
  },
});

const { topAdvisors } = theme.home;
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeTopAdvisors',
};
</script>
